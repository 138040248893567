import type { MessageFunction } from 'vue-i18n';
import homepage from './pages/homepage/index';
import common from './common/index';
import page_system from './pages/system/index';
import page_basic from './pages/basic-data/index';
import page_upload from './pages/upload/index';
import page_parameter from './pages/parameter/index';
import page_report from './pages/main/report/index';
import page_result from './pages/result/index';
import page_policy from './pages/policy/index';
export default {
    license: {
        // eslint-disable-next-line @typescript-eslint/quotes
        powered_by: "上汽国际 {'|'} 版权所有",
        switch_country: '切换国家',
    },
    auth: common.auth.zhCN.auth,
    button: common.button.zhCN,
    radio: common.table.zhCN.radio,
    dropdown: common.table.zhCN.dropdown,
    table: common.table.zhCN.table,
    pagination: {
        pages: ({ named }: Parameters<MessageFunction>[0]) => `${named('number')}/页`,
        total: ({ named }: Parameters<MessageFunction>[0]) => `共 ${named('number')} 条数据`,
    },
    confirm: common.auth.zhCN.confirm,
    request: common.auth.zhCN.request,
    page: {
        global: {
            page_search: '页面搜索',
        },
        error: {
            not_found: '未找到页面',
            not_found_des: '这里没有任何东西',
            auth_failed: '权限不足',
            auth_failed_des: '此页面禁止访问',
        },
        homepage: {
            homepage: homepage.homepage.zhCN,
        },
        main: {
            report: {
                rebate: page_report.rebate.zhCN,
                statistics: page_report.statistics.zhCN,
                santander: page_report.santander.zhCN,
                salesTarget: page_report.salesTarget.zhCN,
            },
        },
        system: {
            menu: page_system.menu.zhCN,
            dict: page_system.dict.zhCN,
            dept: page_system.dept.zhCN,
            role: page_system.role.zhCN,
            user: page_system.user.zhCN,
        },
        basic: {
            dealer: page_basic.dealer.zhCN,
            material: page_basic.material.zhCN,
            systemParameter: page_basic.systemParameter.zhCN,
            modelCategory: page_basic.modelCategory.zhCN,
        },
        upload: {
            coupons: page_upload.coupons.zhCN,
            salesTarget: page_upload.salesTarget.zhCN,
            nonCompliantDealers: page_upload.nonCompliantDealers.zhCN,
            rebateList: page_upload.rebateList.zhCN,
            basicPrice: page_upload.basicPrice.zhCN,
            vehicleModelStandard: page_upload.vehicleModelStandard.zhCN,
            salesVolumeSetting: page_upload.salesVolumeSetting.zhCN,
        },
        parameter: {
            credit: page_parameter.credit.zhCN,
            price: page_parameter.price.zhCN,
            kpi: page_parameter.kpi.zhCN,
            policy: page_parameter.policy.zhCN,
            setting: page_parameter.setting.zhCN,
            policyRule: page_parameter.policyRule.zhCN,
            parameterSetting: page_parameter.parameterSetting.zhCN,
            policyPortfolio: page_parameter.policyPortfolio.zhCN,
            groupSetting: page_parameter.groupSetting.zhCN,
        },
        result: {
            salesOrder: page_result.salesOrder.zhCN,
            rrmgmt: page_result.rrmgmt.zhCN,
            rrapproval: page_result.rrapproval.zhCN,
        },
        document: homepage.document.zhCN,
        policy: {
            financialAccrual: page_policy.financialAccrual.zhCN,
        },
    },
    validation: {
        common: common.validation.zhCN.common,
        tip: common.validation.zhCN.tip,
    },
    import: {
        incorrect_file_type: '文件类型不正确',
        exceed: '超出',
        upload_success: '上传成功',
        upload_fail: '上传失败',
        again_upload: '只能上传 jpg、png、bmp、gif 格式的图片文件，请重新上传',
    },
    export: {
        noDataToExport: '没有可导出数据',
    },
    select: {
        all: '全部',
        input: '请输入',
        p_input: '请输入',
        select: '请选择',
    },
    checkbox: {
        select_all: '全选',
        y: '是',
        n: '否',
    },
    dialog: {
        error_message: '错误信息',
        accessory_selection: '配件选择',
        noData: '没有数据',
    },
    tip: {
        the_start: '第',
        the_end: '列,必填项未填写,如不需要请删除！',
        the_intervals: '列,设置的值与其他区间重复！',
        error_tip: '至少输入一条完整的数据！',
        item_is_required: '此项必填',
        adjustment: '没有修改调整，无需保存',
        select_tip: '至少选择一条数据',
        select_error_tip: '没有需要导出的数据',
        err_quarter_or: '月份或者季度必填一个',
        err_quarter: '季度必填',
        err_month: '月份必填',
        enter: '请输入',
        err_down: '下载错误信息文档',
        export_detail_tip: '导出时必须选择结算周期或返利名称',
        enter_vin: '请输入VIN码',
    },
    tips: common.table.enUS.tips,
};
