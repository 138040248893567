export default {
    financialAccrual: {
        zhCN: {
            number_order: '序号',
            financial_accrual_name: '财务计提名称',
            policy_name: '政策名称',
            policy_number: '政策号',
            date: '日期',
            total_volume: '总成交量',
            total_spend: '总支出',
            spend_per_car: '单车费用',
            create_time: '创建时间',
            operation: '操作',
            accrual_time: '计提时间',
            time_range_of_data_source: '数据源时间范围',
            private_t: 'Private',
            dealer_fleet: 'Dealer Fleet',
            motability: 'Motability',
            demo: 'Demo',
            trim: 'Trim',
            sum_of_sales: 'Sum of Sales',
            sum_of_subsidy: 'Sum of Subsidy/FDA',
            sum_of_vms: 'Sum of VMS',
            per_car_vms: 'Per car VMS',
            materialCode: '物料号',
            vms: 'VMS',
            fda_subsidy: 'FDA/Subsidy',
        },
        enUS: {
            number_order: 'No.',
            financial_accrual_name: 'Financial Accrual Name',
            policy_name: 'Policy Name',
            policy_number: 'Policy Number',
            date: 'Date',
            total_volume: 'Total Volume',
            total_spend: 'Total Spend',
            spend_per_car: 'Spend Per Car',
            create_time: 'Create Time',
            operation: 'Operation',
            accrual_time: 'Accrual Time',
            time_range_of_data_source: 'Time Range of Data Source',
            private_t: 'Private',
            dealer_fleet: 'Dealer Fleet',
            motability: 'Motability',
            demo: 'Demo',
            trim: 'Trim',
            sum_of_sales: 'Sum of Sales',
            sum_of_subsidy: 'Sum of Subsidy/FDA',
            sum_of_vms: 'Sum of VMS',
            per_car_vms: 'Per car VMS',
            materialCode: 'Material Code',
            vms: 'VMS',
            fda_subsidy: 'FDA/Subsidy',
        },
    },
};
