import type { MessageFunction } from 'vue-i18n';

export default {
    zhCN: {
        common: {
            required: '此项必填',
            numeric: '必须为整数数字',
            decimal: '必须为数字',
            positive_interger: '必须为正整数',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `最多为${named('number')}位数`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `不能超过${named('number')}字`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `超过最大字符数(${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `不少于${named('number')}字`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `必须为${named('number')}字`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `不能大于${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) => `不能小于${named('number')}`,
            up_to_2_decimal_places: '最多为2位小数',
            retain_up: '最多输入10位正整数，最多保留2位小数',
            retain_ups: '最多输入11位正整数，最多保留4位小数',
            input_number: '请输入数字',
            only_number_a: '只能输入字母或数字',
            only_number_a_: '只能输入字母,数字，下划线',
            translate: '只能为字母',
            positive_integer: '至少输入一个字段',
            at_least_one: '至少填写一个',
        },
        tip: {
            incomplete_information: '信息未填写完整',
            MG_information: 'MG订单员不能为空',
            MAXUS_information: 'MAXUS订单员不能为空',
            mailbox_format_error: '邮箱格式错误',
            required_tip: '月份或者季度必填一个',
            select: '请选择',
            date_format_error: '日期格式错误',
        },
    },
    enUS: {
        common: {
            required: 'Item is required',
            numeric: 'Must be integer',
            decimal: 'Must be number',
            positive_interger: 'Must be positive interger',
            up_to_digits: ({ named }: Parameters<MessageFunction>[0]) =>
                `Up to ${named('number')} digits`,
            more_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `More than ${named('number')} characters`,
            max_bytes: ({ named }: Parameters<MessageFunction>[0]) =>
                `Exceed max bytes (${named('number')})`,
            less_than_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Less than ${named('number')} characters`,
            must_be_characters: ({ named }: Parameters<MessageFunction>[0]) =>
                `Must be ${named('number')} characters`,
            greater_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Greater than ${named('number')}`,
            less_than: ({ named }: Parameters<MessageFunction>[0]) =>
                `Less than ${named('number')}`,
            up_to_2_decimal_places: 'Up to 2 decimal places',
            retain_up: 'Enter up to 10 positive integers and retain up to 4 decimal places',
            retain_ups: 'Enter up to 11 positive integers, and retain up to 4 decimal places',
            input_number: 'Please enter the number',
            only_number_a: 'Only letters or numbers can be entered',
            only_number_a_: 'Only letters or numbers or "_" can be entered',
            positive_integer: 'Enter at least one field',
            at_least_one: 'Fill in at least one',
        },
        tip: {
            incomplete_information: 'Incomplete Information',
            MG_information: 'MG orderer cannot be empty',
            MAXUS_information: 'MAXUS orderer cannot be empty',
            mailbox_format_error: 'Mailbox format error',
            required_tip: 'Month or quarter must be filled in',
            select: 'Please select',
            date_format_error: 'Date format error',
        },
    },
};
